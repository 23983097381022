import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config/config'

export const useGetAsicsReviews = (id, checkRefetch) => {
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(true)
   useEffect(() => {
       if(id){
           axios
               .get(`${baseUrl}/asics/${id}/reviews`)
               .then((response) => {
                   const data = response.data
                   setData(data)
                   setIsLoading(false)
               })
               .catch((error) => {
                   console.error('Произошла ошибка:', error)
                   setIsLoading(false)
               })
       }
   }, [id, checkRefetch])
   return { data, isLoading }
}
