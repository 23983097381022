import classes from './ButtonFavorite.module.scss'
import { useContext, useEffect, useState } from 'react'
import ActiveFavoritesIcon from '../../../../accests/image/activeFavorites.svg'
import requared from '../../axiosIntercepter'
import { baseUrl } from '../../../config/config'

import { ProfileContext } from '../../../../App'

function ButtonFavorite({ itemId = 1, tooltip }) {
   const context = useContext(ProfileContext)
   const [activeFavoriteIcon, setActive] = useState(false)
   const [authName] = useState(localStorage.getItem('authName'))

   function favoriteToggleAdd() {
      if (authName !== '' && authName !== null) {
         requared
            .post(baseUrl + '/favorite', { asic_id: itemId })
            .then((response) => {
               setActive(true)
               context.storeDispatch({ type: 'renew', payload: itemId })
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.status, error.statusText)
            })
      } else {
         context.storeDispatch({
            type: 'popup_toggle',
            payload: true,
         })
      }
   }

   function favoriteToggleDelete() {
      if (authName !== '' && authName !== null) {
         requared
            .delete(baseUrl + '/favorite', { data: { asic_id: itemId } })
            .then((response) => {
               setActive(false)
               context.storeDispatch({
                  type: 'rerender',
                  payload: context.profileStore.favorite.filter((element) => element.id !== itemId),
               })
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.status, error.statusText)
            })
      } else {
         context.storeDispatch({
            type: 'popup_toggle',
            payload: true,
         })
      }
   }

   useEffect(() => {
      context.profileStore.favorite.map((element) =>
         element.id === itemId ? setActive(true) : null,
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [context.profileStore.favorite])

   return (
      <>
         {activeFavoriteIcon === false ? (
            <div className={classes.labelActive} onClick={() => favoriteToggleAdd()}>
               <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0003 18.0418C9.74199 18.0418 9.49199 18.0085 9.28366 17.9335C6.10033 16.8418 1.04199 12.9668 1.04199 7.24183C1.04199 4.32516 3.40033 1.9585 6.30033 1.9585C7.70866 1.9585 9.02533 2.5085 10.0003 3.49183C10.9753 2.5085 12.292 1.9585 13.7003 1.9585C16.6003 1.9585 18.9587 4.3335 18.9587 7.24183C18.9587 12.9752 13.9003 16.8418 10.717 17.9335C10.5087 18.0085 10.2587 18.0418 10.0003 18.0418ZM6.30033 3.2085C4.09199 3.2085 2.29199 5.01683 2.29199 7.24183C2.29199 12.9335 7.76699 16.1002 9.69199 16.7585C9.84199 16.8085 10.167 16.8085 10.317 16.7585C12.2337 16.1002 17.717 12.9418 17.717 7.24183C17.717 5.01683 15.917 3.2085 13.7087 3.2085C12.442 3.2085 11.267 3.80016 10.5087 4.82516C10.2753 5.14183 9.74199 5.14183 9.50866 4.82516C8.73366 3.79183 7.56699 3.2085 6.30033 3.2085Z" fill="#0B0C0E"/>
               </svg>
               {tooltip && <span>В избранное</span>}
            </div>
         ) : (
            <div className={classes.labelActive} onClick={() => favoriteToggleDelete()}>
               <img src={ActiveFavoritesIcon} alt='ActiveFavoritesIcon' />
               {tooltip && <span>В избранное</span>}
            </div>
         )}
      </>
   )
}

export default ButtonFavorite
