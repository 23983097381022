import classes from './Contacts.module.scss'

import PageSpinner from '../pageSpinner/PageSpinner'
import Crums from '../breadCrums/Crums'
import ContactsMapYandex from './contactsMap/ContactsMapYandex'
import { useEffect, useState } from 'react'
import insta from '../../../accests/image/insta_soc.svg'
import watsup from '../../../accests/image/watsup.svg'
import telega from '../../../accests/image/teleg.svg'
import youtube from '../../../accests/image/youtube.svg'
import vk from '../../../accests/image/vk.svg'
// import image1 from '../../../accests/image/image 25_of1.png'
import MapAdress from './contactsItems/ContactsItem'
import { useGetContacts } from '../../hooks/useGetContacts'
import { imageUrl } from '../../config/config'

const ContactsYap = () => {
   const [activeContact, setActiveContact] = useState(0)
   const { data: contact, isLoading: isContact } = useGetContacts()

   const [contactsData, setContactsData] = useState([
      {
         socials: {
            watsup: { name: 'watsup', src: watsup, link: 'https://wa.me/79150199532' },
            telega: { name: 'telega', src: telega, link: 'https://t.me/+4T9KOMcJ6kk3NTNi' },
            insta: { name: 'insta', src: insta, link: 'https://instagram.com/imine.russia' },
            youtube: { name: 'youtube', src: youtube, link: 'https://www.youtube.com/@imineofficiall' },
            vk: { name: 'vk', src: vk, link: 'https://vk.com/imineofficial' },
         },
         id: 1,
         image: '',
         name: '',
         adress: '',
         phone: '',
         mail: '',
         coor: [],
      },
   ])
   const [spinner, setSpinner] = useState(true)
   const crumsData = [['contacts', 'Контакты']]
   function toggleContact(value) {
      setSpinner(false)
      setActiveContact(value)
   }
   useEffect(() => {
      return () => {
         setSpinner(true)
      }
   }, [spinner])

   useEffect(() => {
      setContactsData([
         {
            socials: {
               watsup: { name: 'watsup', src: watsup, link: contact.wa },
               telega: { name: 'telega', src: telega, link: contact.telegram },
               insta: { name: 'insta', src: insta, link: 'https://instagram.com/imine.russia' },
               youtube: {
                  name: 'youtube',
                  src: youtube,
                  link: contact.youtube,
               },
               vk: { name: 'vk', src: vk, link: contact.vk },
            },
            id: 1,
            image: imageUrl + contact.store_url,
            name: contact.name,
            adress: contact.address,
            phone: contact.phone,
            mail: contact.email,

            coor: !!contact?.coordinates?.length
               ? contact.coordinates.split(',').map(Number)
               : [0, 0],
         },
      ])
   }, [isContact, contact])
   return (
      <>
         {!isContact ? (
            <>
               <div className={classes.contactMain + ' container'}>
                  <Crums data={crumsData} />
                  <h3 id='contacts-header'>Контакты</h3>
                  {spinner ? (
                     <ContactsMapYandex activeMarker={activeContact} data={contactsData} />
                  ) : (
                     <PageSpinner />
                  )}
                  {spinner ? (
                     <div className={classes.mapItemsList}>
                        <div>
                           {contactsData.map((element, index) => {
                              return (
                                 <MapAdress
                                    data={element}
                                    key={index}
                                    toggleActiveContact={() => {}}
                                    isActive={activeContact}
                                 />
                              )
                           })}
                        </div>
                     </div>
                  ) : (
                     <PageSpinner />
                  )}
               </div>
            </>
         ) : (
            <PageSpinner />
         )}
      </>
   )
}

export default ContactsYap
